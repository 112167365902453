import { number } from "yup";
import { create } from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  openSnackbar: false,
  openSnackbarError: false,
  snackbarMessage: "",
  profileData: {},
  userOpen: false,
  users: [],
  userId: "",
  addPointOpen: false,
  WithdrawOpen: false,
  requestId: "",
  withdrawId: "",
  depositOpen: false,
  deposit1Open: false,
  depositRevertOpen: false,
  withdrawRequestOpen: false,
  withdrawRequest1Open: false,
  depositRow: [],
  withdrawRow: [],
  userDefaultBalance: number,
  maximumWithdrawAmount: number,
  minimumWithdrawAmount: number,
  minimumWalletBalance: number,
  walletBalance: "0",
  walletBalanceUpdated: false,
  setWalletBalanceUpdated: (value) =>
    set((state) => ({ walletBalanceUpdated: value })),

  selectedMenuFormation: [],
  setSelectedMenuFormation: (value) =>
    set((state) => ({ selectedMenuFormation: value })),

  updateOrAddMenu: (menu, games) => {
    set((state) => {
      const menuIndex = state.selectedMenuFormation.findIndex(
        (selectedMenu) => selectedMenu.menu === menu
      );

      console.log(menuIndex);
      console.log(menu);
      if (menuIndex !== -1) {
        // Menu with menuId exists, update it
        console.log("if");
        const updatedMenuFormation = [...state.selectedMenuFormation];
        updatedMenuFormation[menuIndex] = { menu, games };
        return { selectedMenuFormation: updatedMenuFormation };
      } else {
        // Menu with menu doesn't exist, add a new object
        console.log("else");
        return {
          selectedMenuFormation: [
            ...state.selectedMenuFormation,
            { menu, games },
          ],
        };
      }
    });
  },

  removeMenuById: (menuId) => {
    set((state) => {
      const menuIndex = state.selectedMenuFormation.findIndex(
        (selectedMenu) => selectedMenu.menu === menuId
      );
      if (menuIndex !== -1) {
        const updatedMenuFormation = [...state.selectedMenuFormation];
        updatedMenuFormation.splice(menuIndex, 1);
        return { selectedMenuFormation: updatedMenuFormation };
      } else {
        return { selectedMenuFormation: [...state.selectedMenuFormation] };
      }
    });
  },

  numberValidated: false,
  setNumberValidated: (value) => set((state) => ({ numberValidated: value })),

  creditLimit: "",
  setCreditLimit: (value) => set((state) => ({ creditLimit: value })),

  isNumberValid: false,
  setIsNumberValid: (value) => set((state) => ({ isNumberValid: value })),

  validNumber: "",
  setValidNumber: (value) => set((state) => ({ validNumber: value })),

  openFigureError: "",
  setOpenFigureError: (value) => set((state) => ({ openFigureError: value })),

  checkNumbers: [],
  setCheckNumbers: (value) => set((state) => ({ checkNumbers: value })),

  filteredGames: [],
  setFilteredGames: (value) => set((state) => ({ filteredGames: value })),

  selectedGameIds: [],
  setSelectedGameIds: (value) => set((state) => ({ selectedGameIds: value })),

  declaredResults: [],
  setDeclaredResults: (value) => set((state) => ({ declaredResults: value })),

  filteredMenus: [],
  setFilteredMenus: (value) => set((state) => ({ filteredMenus: value })),

  selectedNavigateMenusIds: [],
  setSelectedNavigateMenusIds: (value) =>
    set((state) => ({ selectedNavigateMenusIds: value })),

  selectedMenusIds: [],
  setSelectedMenusIds: (value) => set((state) => ({ selectedMenusIds: value })),

  selectedMenuId: "",
  setSelectedMenuId: (value) => set((state) => ({ selectedMenuId: value })),

  selectedMenuDisplayName: "",
  setSelectedMenuDisplayName: (value) =>
    set((state) => ({ selectedMenuDisplayName: value })),

  selectedGameIds: [],
  setSelectedGameIds: (value) => set((state) => ({ selectedGameIds: value })),

  selectedGamesByMenuId: [],
  setSelectedGamesByMenuId: (value) =>
    set((state) => ({ selectedGamesByMenuId: value })),

  selectedGamesForDialog: [],
  setSelectedGamesForDialog: (value) =>
    set((state) => ({ selectedGamesForDialog: value })),

  selectedGamesCheck: [],
  setSelectedGamesCheck: (value) =>
    set((state) => ({ selectedGamesCheck: value })),

  selectedGame: {},
  setSelectedGame: (value) => set((state) => ({ selectedGame: value })),

  games: [],
  setGames: (value) => set((state) => ({ games: value })),

  menus: [],
  setMenus: (value) => set((state) => ({ menus: value })),

  selectMenusOpen: false,
  setSelectMenusOpen: (value) => set((state) => ({ selectMenusOpen: value })),

  filteredGames: [],
  setFilteredGames: (value) => set((state) => ({ filteredGames: value })),

  formatedGames: [],
  setForamtedGames: (value) => set((state) => ({ formatedGames: value })),

  gameOpenTime: Number,
  setGameOpenTime: (value) => set((state) => ({ gameOpenTime: value })),

  setWalletBalance: (value) => set((state) => ({ walletBalance: value })),
  userDetails: {
    disabled: false, // Initial value for disabled
    // Other user details fields
  },
  partialAmount: "",
  setPartialAmount: (value) => set((state) => ({ partialAmount: value })),
  setUserDetailsDisable: (fieldName, value) =>
    set((state) => ({
      userDetails: {
        ...state.userDetails, // Preserve other fields
        [fieldName]: value, // Update the specified field
      },
    })),

  successDialog: false,
  setSuccessDialog: (value) => set((state) => ({ successDialog: value })),
  // closeDialog: () => set((state) => ({ successDialog: false })),
  loading: false,
  setLoading: (value) => set((state) => ({ loading: value })),

  // In your appStore
  message: [],
  setMessage: (newMessage) =>
    set((state) => {
      // Check if the new message already exists
      const messageExists = state.message.some(
        (msg) => msg._id === newMessage._id
      );
      if (!messageExists) {
        return {
          message: [...state.message, newMessage], // Append newMessage to the existing array of messages
        };
      }
      return state; // Return unchanged state if message exists
    }),

  openedMessages: [],
  setOpenedMessages: (value) => set((state) => ({ openedMessages: value })),

  isMessageOpened: false,
  setIsMessageOpened: (value) => set((state) => ({ isMessageOpened: value })),

  selectedDateHistory: "",
  setSelectedDateHistory: (value) =>
    set((state) => ({ selectedDateHistory: value })),

  amountConfirmLoading: false,
  setAmountConfirmLoading: (value) =>
    set((state) => ({ amountConfirmLoading: value })),

  loadingGames: false,
  setLoadingGames: (value) => set((state) => ({ loadingGames: value })),

  referralUserCount: 0,
  setReferralUserCount: (value) =>
    set((state) => ({ referralUserCount: value })),

  holidayList: [],
  setHolidayList: (value) => set((state) => ({ holidayList: value })),

  userReport: [],
  setUserReport: (value) => set((state) => ({ userReport: value })),
  games: [],
  setGames: (value) => set((state) => ({ games: value })),
  profileDetails: {},
  setProfileDetails: (value) => set((state) => ({ profileDetails: value })),
  depositSent: false,
  setDepositSent: (value) => set((state) => ({ depositSent: value })),
  profileDetailsUpdate: {},
  setProfileDetailsUpdate: (value) =>
    set((state) => ({ profileDetailsUpdate: value })),
  userDetailOpen: false,
  setDepositRevertOpen: (value) =>
    set((state) => ({ depositRevertOpen: value })),
  setUserDetailOpen: (value) => set((state) => ({ userDetailOpen: value })),
  setUserDetails: (value) => set((state) => ({ userDetails: value })),
  setMinimumWalletBalance: (value) =>
    set((state) => ({ minimumWalletBalance: value })),
  setMinimunWithdrawAmount: (value) =>
    set((state) => ({ minimumWithdrawAmount: value })),
  setMaximumWithdrawAmount: (value) =>
    set((state) => ({ maximumWithdrawAmount: value })),
  setUserDefaultBalance: (value) =>
    set((state) => ({ userDefaultBalance: value })),
  setWithdrawRow: (value) => set((state) => ({ withdrawRow: value })),
  setWithdrawRequestOpen: (value) =>
    set((state) => ({ withdrawRequestOpen: value })),
  setWithdrawRequest1Open: (value) =>
    set((state) => ({ withdrawRequest1Open: value })),
  setDepositRow: (value) => set((state) => ({ depositRow: value })),
  setDepositOpen: (value) => set((state) => ({ depositOpen: value })),
  setDeposit1Open: (value) => set((state) => ({ deposit1Open: value })),
  setRequestId: (value) => set((state) => ({ requestId: value })),
  setWithdrawId: (value) => set((state) => ({ withdrawId: value })),
  setUserOpen: (value) => set((state) => ({ userOpen: value })),
  setUserId: (value) => set((state) => ({ userId: value })),
  setAddPointOpen: (value) => set((state) => ({ addPointOpen: value })),
  setWithdrawOpen: (value) => set((state) => ({ WithdrawOpen: value })),
  setUsers: (value) => set((state) => ({ users: value })),
  setProfileData: (value) => set((state) => ({ profileData: value })),
  setOpenSnackbar: (value) => set((state) => ({ openSnackbar: value })),
  setOpenSnackbarError: (value) =>
    set((state) => ({ openSnackbarError: value })),
  setSnackbarMessage: (value) => set((state) => ({ snackbarMessage: value })),
});

appStore = persist(appStore, {
  name: "mathcustomerstore",
  getStorage: () => sessionStorage,
});
export const useAppStore = create(appStore);
