import * as React from "react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Divider, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import { useAppStore } from "../../appStore";
import moment from "moment";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#ff6d00",
    color: "#ffffff",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
      color: "#ff6d00",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.6)",
      opacity: 0,
    },
  },
}));

const MessageCard = styled(CardContent)(({ isNew }) => ({
  padding: "3px 2px 2px 10px",
  margin: "0.5rem",
  background: "#f5f5f5",
  borderRadius: 3,
  cursor: "pointer",
  borderLeft: isNew && "4px solid #673ab7",
}));

export default function Notification() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMessage, setSelectedMessage] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const { profileData, message, setMessage } = useAppStore();

  // Load opened message IDs from session storage
  const [openedMessageIds, setOpenedMessageIds] = useState(() => {
    const storedIds = sessionStorage.getItem("openedMessageIds");
    return storedIds ? new Set(JSON.parse(storedIds)) : new Set();
  });

  useEffect(() => {
    const socket = io("https://api.smindiagroup.com/");
    const userId = profileData?._id;

    if (userId) {
      socket.emit("joinRoom", userId);

      // Listen for new messages
      socket.on("messageUsers", (data) => {
        console.log("New message received:", data);
        setMessage(data); // Update your state to include the new message
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [profileData, setMessage]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (msg) => {
    if (msg && msg._id) {
      setOpenedMessageIds((prev) => {
        const updatedIds = new Set(prev).add(msg._id);
        sessionStorage.setItem(
          "openedMessageIds",
          JSON.stringify(Array.from(updatedIds))
        );
        return updatedIds;
      });
      setSelectedMessage(msg);
      handleClose();
      setOpenModal(true);
    } else {
      console.error("Invalid message object:", msg);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack
      spacing={4}
      direction="row"
      sx={{ color: "action.active", cursor: "pointer" }}
    >
      <StyledBadge
        color="error"
        badgeContent={message.length - openedMessageIds.size}
        showZero
        onClick={handleClick}
      >
        <MailIcon sx={{ color: { xs: "#e0e0e0", md: "#757575" } }} />
      </StyledBadge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 3 }}
      >
        <Card sx={{ width: "240px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#1e2466" }}
            >
              Messages
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: "bold", color: "#ff5722" }}
            >
              Unread ({message.length - openedMessageIds.size})
            </Typography>
          </Box>

          <Box
            sx={{
              mr: 0.5,
              maxHeight: "250px",
              overflowY: "auto",
              "&:hover": {
                overflowY: "auto",
              },
              "&::-webkit-scrollbar": {
                width: "6px",
                display: "none",
              },
              "&:hover::-webkit-scrollbar": {
                display: "block",
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: `#e9e9e9`,
                borderRadius: "4px",
              },
            }}
          >
            {message.length > 0 ? (
              message.map((msg) => (
                <MessageCard
                  key={msg._id}
                  isNew={!openedMessageIds.has(msg._id)}
                  onClick={() => handleOpenModal(msg)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {msg.heading}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {msg.message}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "10px", color: "#80cbc4" }}
                  >
                    {moment(msg.createdAt).fromNow()}
                  </Typography>
                </MessageCard>
              ))
            ) : (
              <CardContent sx={{ textAlign: "center" }}>
                <Typography>No messages</Typography>
              </CardContent>
            )}
          </Box>
        </Card>
      </Popover>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Card sx={{ width: 400 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
              }}
            >
              <Typography variant="h6">Message</Typography>
              <IconButton onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <CardContent>
              {selectedMessage && (
                <>
                  <Typography variant="subtitle1">
                    {selectedMessage.heading}
                  </Typography>
                  <Typography variant="body2">
                    {selectedMessage.message}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {moment(selectedMessage.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Stack>
  );
}
