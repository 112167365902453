import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  TableCell,
  TableRow,
  TablePagination,
  CircularProgress,
  Chip,
  Button,
  Box,
  TableSortLabel,
  Card,
  CardContent,
  CardHeader,
  Grid,
  useMediaQuery,
  tableCellClasses,
  Skeleton,
  Select,
  MenuItem,
  Divider,
  TextField,
  IconButton,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { useAppStore } from "../../appStore";
import { api_url } from "../../apiUtils";
import * as XLSX from "xlsx";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6e8fc",
    color: "#1e2466",
    border: "1px solid #bdbdbd !important",
    fontWeight: "bold",
    letterSpacing: 0.5,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #bdbdbd !important",
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F9FB",
  },
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const commonIconStyles = {
  fontSize: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  border: "2px solid",
  marginRight: "5px",
};

const commonChipStyle = {
  width: "90px",
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  fontSize: "12px",
  color: "#fff",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  textTransform: "capitalize",
};

const WalletStatements = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const { selectedDateHistory, setSelectedDateHistory } = useAppStore();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterOpen, setFilterOpen] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const url = selectedDateHistory
        ? `${api_url}wallet/user-statements?date=${selectedDateHistory}`
        : `${api_url}wallet/user-statements`;

      const response = await axios.get(url, {
        headers: { accesstoken: sessionStorage.getItem("accesstoken") },
      });
      const finalReport = response.data.statements;
      setTimeout(() => {
        setData(finalReport);
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.log("Error fetching data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedDateHistory]);

  useEffect(() => {
    setSelectedDateHistory("");
    setData([]);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterType = (type) => {
    setSelectedType(type);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSortRequest = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredData = data
    .filter((statement) =>
      selectedType ? statement.type === selectedType : true
    )
    .filter((statement) =>
      selectedStatus ? statement.status === selectedStatus : true
    )
    .filter((statement) =>
      searchQuery ? statement.transactionId === searchQuery : true
    );
  console.log("searchquery", searchQuery);
  const sortedData = filteredData.sort((a, b) => {
    if (sortOrder === "asc") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });

  const handleCopyTransactionId = (transactionId) => {
    navigator.clipboard.writeText(transactionId);
  };

  // Determine if we should slice or show all
  const displayData = selectedDateHistory ? sortedData : sortedData.slice(0, 5);

  const exportToExcel = (array) => {
    const ws = XLSX.utils.json_to_sheet(
      array.map((item) => ({
        Type: item.type,
        "Previous Balance": item.previousBalance.toFixed(2),
        "Amount (Credit / Debit)": `${
          item.type === "withdraw" ||
          item.type === "revert" ||
          item.status === "rejected"
            ? "-"
            : "+"
        }${Math.abs(item.amount)}`,
        "Net Balance": item.netBalance.toFixed(2),
        "Date & Time": new Date(item.createdAt).toLocaleString(),
        Status: item.status,
      }))
    );

    // Add styling
    const wscols = [
      { wch: 10 },
      { wch: 20 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 25 },
    ];
    ws["!cols"] = wscols;
    ws["!rows"] = [{ hpx: 20 }];

    // Header style
    const headerStyle = {
      fill: { fgColor: { rgb: "#FFFF00" } },
      font: { color: { rgb: "#FFFFFF" }, bold: true },
      alignment: { horizontal: "center", vertical: "center" },
    };

    ws["A1"].s = headerStyle;
    ws["B1"].s = headerStyle;
    ws["C1"].s = headerStyle;
    ws["D1"].s = headerStyle;
    ws["E1"].s = headerStyle;
    ws["F1"].s = headerStyle;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Wallet Statements");
    XLSX.writeFile(
      wb,
      `wallet_statements_${
        selectedDateHistory ? selectedDateHistory : Date.now()
      }.xlsx`
    );
  };

  const getTypeStyles = (type) => {
    const bgColor =
      (type === "deposit" && "#4caf50") ||
      (type === "withdraw" && "#ef5350") ||
      (type === "revert" && "#ffc107");

    return {
      ...commonChipStyle,
      bgcolor: bgColor,
      "&:hover": {
        bgcolor: bgColor,
      },
    };
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "completed":
        return {
          icon: (
            <CheckIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#388e3c",
                color: "#388e3c",
              }}
            />
          ),
          color: "#388e3c",
        };
      case "requested":
        return {
          icon: (
            <ArrowOutwardIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#1782FF",
                color: "#1782FF",
              }}
            />
          ),
          color: "#1782FF",
        };
      case "rejected":
        return {
          icon: (
            <CloseIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#ff3d00",
                color: "#ff3d00",
              }}
            />
          ),
          color: "#ff3d00",
        };
      case "verifying":
        return {
          icon: (
            <QuestionMarkIcon
              sx={{
                ...commonIconStyles,
                borderColor: "#ffc107",
                color: "#ffc107",
              }}
            />
          ),
          color: "#ffc107",
        };
      default:
        return { icon: null, color: "default" };
    }
  };

  return (
    <Box sx={{ mt: 1 }}>
      {/* {isMobile ? "" : <Typography>Filter Statements:</Typography>} */}

      {isMobile ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setFilterOpen(!isFilterOpen)}
              startIcon={<FilterAltIcon />}
              sx={{ mb: 2 }}
              color="warning"
            >
              Filter
            </Button>
          </Box>

          <Collapse in={isFilterOpen}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    // flexWrap: "wrap",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  <Chip
                    label="All"
                    onClick={() => handleFilterType("")}
                    sx={{
                      ...commonChipStyle,
                      bgcolor: selectedType === "" ? "#1782FF" : "#f0f0f0",
                      cursor: "pointer",
                    }}
                  />
                  {["deposit", "withdraw", "revert"].map((type) => (
                    <Chip
                      key={type}
                      label={type}
                      onClick={() => handleFilterType(type)}
                      sx={{
                        ...commonChipStyle,
                        bgcolor:
                          selectedType === type
                            ? getTypeStyles(type).bgcolor
                            : "#bdbdbd",
                        cursor: "pointer",
                        "&:hover": {
                          bgcolor:
                            selectedType === type
                              ? getTypeStyles(type).bgcolor
                              : "#a3a3a3",
                        },
                      }}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  <Select
                    size="small"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Status" }}
                    sx={{ width: "100%", maxWidth: 200 }}
                  >
                    <MenuItem value="">All Statuses</MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

                    <MenuItem
                      value="completed"
                      sx={{ color: getStatusIcon("completed").color }}
                    >
                      Completed
                    </MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />
                    <MenuItem
                      value="requested"
                      sx={{ color: getStatusIcon("requested").color }}
                    >
                      Requested
                    </MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

                    <MenuItem
                      value="rejected"
                      sx={{ color: getStatusIcon("rejected").color }}
                    >
                      Rejected
                    </MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

                    <MenuItem
                      value="verifying"
                      sx={{ color: getStatusIcon("verifying").color }}
                    >
                      Verifying
                    </MenuItem>
                  </Select>
                </Box>

                {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body2">Search by Transaction ID:</Typography>
              <TextField
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Enter Transaction ID"
                sx={{ width: "100%", maxWidth: 300 }}
              />
            </Box> */}

                <Button
                  variant={{ xs: "outlined", xl: "contained" }}
                  sx={{
                    // width: { xs: "100%", sm: "auto" },
                    // px: 3,
                    textTransform: "capitalize",
                    bgcolor: isMobile ? "" : "#2e7d32",
                    color: isMobile ? "#ef6c00" : "#ffffff",
                    letterSpacing: 1,
                    border: "1px solid #ff9800",
                    whiteSpace: "nowrap",
                    fontSize: "0.8rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => exportToExcel(filteredData)}
                >
                  {isMobile ? "Download Statement" : "Export .xlsx"}
                  <DownloadIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>
              {/* <Grid item xs={12} sm={6}></Grid> */}
            </Grid>
          </Collapse>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setFilterOpen(!isFilterOpen)}
              startIcon={<FilterAltIcon />}
              sx={{ mb: 2 }}
              color="warning"
            >
              Filter
            </Button>
          </Box>
          <Collapse in={isFilterOpen}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} xl={4}>
                <Box
                  sx={{
                    display: "flex",
                    // flexWrap: "wrap",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  <Chip
                    label="All"
                    onClick={() => handleFilterType("")}
                    sx={{
                      ...commonChipStyle,
                      bgcolor: selectedType === "" ? "#1782FF" : "#f0f0f0",
                      cursor: "pointer",
                    }}
                  />
                  {["deposit", "withdraw", "revert"].map((type) => (
                    <Chip
                      key={type}
                      label={type}
                      onClick={() => handleFilterType(type)}
                      sx={{
                        ...commonChipStyle,
                        bgcolor:
                          selectedType === type
                            ? getTypeStyles(type).bgcolor
                            : "#bdbdbd",
                        cursor: "pointer",
                        "&:hover": {
                          bgcolor:
                            selectedType === type
                              ? getTypeStyles(type).bgcolor
                              : "#a3a3a3",
                        },
                      }}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  <Select
                    size="small"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Status" }}
                    sx={{ width: "100%", maxWidth: 200 }}
                  >
                    <MenuItem value="">All Statuses</MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

                    <MenuItem
                      value="completed"
                      sx={{ color: getStatusIcon("completed").color }}
                    >
                      Completed
                    </MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />
                    <MenuItem
                      value="requested"
                      sx={{ color: getStatusIcon("requested").color }}
                    >
                      Requested
                    </MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

                    <MenuItem
                      value="rejected"
                      sx={{ color: getStatusIcon("rejected").color }}
                    >
                      Rejected
                    </MenuItem>
                    <Divider sx={{ m: "2px 4px 0px 4px !important" }} />

                    <MenuItem
                      value="verifying"
                      sx={{ color: getStatusIcon("verifying").color }}
                    >
                      Verifying
                    </MenuItem>
                  </Select>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
                  <Typography variant="body2">
                    Search by Transaction ID:
                  </Typography>
                  <TextField
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Enter Transaction ID"
                    sx={{ width: "100%", maxWidth: 300 }}
                  />
                </Box>

                <Button
                  variant={{ xs: "outlined", xl: "contained" }}
                  sx={{
                    // width: { xs: "100%", sm: "auto" },
                    // px: 3,
                    textTransform: "capitalize",
                    bgcolor: isMobile ? "" : "#2e7d32",
                    color: isMobile ? "#ef6c00" : "#ffffff",
                    letterSpacing: 1,
                    whiteSpace: "nowrap",
                    fontSize: "0.8rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => exportToExcel(filteredData)}
                >
                  {isMobile ? "Download Statement" : "Export .xlsx"}
                  <DownloadIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>
              {/* <Grid item xs={12} sm={6}></Grid> */}
            </Grid>
          </Collapse>
        </>
      )}

      {loading ? (
        isMobile ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
            }}
          >
            {Array.from({ length: 5 }).map((_, index) => (
              <Card
                key={index}
                sx={{ width: "100%", mb: 2, mt: { xs: 2, sm: 0 } }}
              >
                <CardHeader
                  title={<Skeleton variant="text" width="40%" />}
                  subheader={<Skeleton variant="text" width="60%" />}
                />
                <CardContent>
                  <Skeleton variant="rectangular" height={60} />
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </div>
        )
      ) : (
        <>
          {isMobile ? (
            // Mobile view using cards
            <Box sx={{ mt: 2 }}>
              {displayData?.length === 0 && (
                <Card sx={{ width: "100%", mb: 2 }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ color: "red" }}>
                      No Data Found.... 😔
                    </Typography>
                  </CardContent>
                </Card>
              )}
              {/* <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="body2">
                    Search by Transaction ID:
                  </Typography>
                  <TextField
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Enter Transaction ID"
                    sx={{ width: "100%", maxWidth: 300 }}
                  />
                </Box>
              </Grid> */}

              {displayData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((statement) => {
                  const { icon, color } = getStatusIcon(statement.status);
                  return (
                    <Card
                      key={statement._id}
                      sx={{ mb: 1, mt: { xs: 2, sm: 0 } }}
                    >
                      <CardHeader
                        title={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{statement.type} </span>
                            <span
                              style={{
                                fontSize: "0.7rem", // Smaller font size
                                color: "#757575", // Red color
                                fontWeight: "normal !important",
                              }}
                            >
                              #{statement.transactionId}
                            </span>
                          </div>
                        }
                        subheader={`Date: ${new Date(
                          statement.createdAt
                        ).toLocaleString()}`}
                        sx={{
                          // color: getTypeStyles(statement.type).bgcolor,
                          bgcolor: "#e6e8fc",
                          color:
                            statement.type === "withdraw" ||
                            statement.type === "revert" ||
                            statement.status === "rejected"
                              ? "#f44336"
                              : statement.type === "deposit" &&
                                statement.status === "completed"
                              ? "green"
                              : statement.status === "requested" &&
                                statement.type === "deposit"
                              ? "#42a5f5"
                              : "",
                          // color: "#fff", // This applies color to both title and subheader
                          "& .MuiCardHeader-title": {
                            // color: "#fff", // Ensures title is white
                            letterSpacing: 0.5,
                            fontSize: "1rem",
                            fontWeight: "bold",
                          },
                          "& .MuiCardHeader-subheader": {
                            color: "#1e2466", // Ensures subheader is white
                            fontSize: "0.8rem",
                            letterSpacing: 0.5,
                            fontWeight: "bold",
                          },
                          textTransform: "capitalize",
                          p: "6px 8px",
                        }}
                      />

                      <CardContent
                        sx={{
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          pb: "6px !important",
                        }}
                      >
                        <Box>
                          <Typography variant="body2">
                            <strong>Previous Balance:</strong>{" "}
                            <span
                              style={{
                                color: statement.previousBalance < 0 && "red",
                              }}
                            >
                              {" "}
                              {statement.previousBalance.toFixed(2)}
                            </span>
                          </Typography>
                          <Typography variant="body2" sx={{ my: 1 }}>
                            <strong>Amount:</strong>{" "}
                            <span
                              style={{
                                color:
                                  statement.type === "withdraw" ||
                                  statement.type === "revert"
                                    ? "red"
                                    : statement.type === "deposit" &&
                                      statement.status === "completed"
                                    ? "green"
                                    : statement.status === "requested" &&
                                      statement.type === "deposit"
                                    ? "#1782FF"
                                    : statement.type === "withdraw" &&
                                      statement.status === "rejected"
                                    ? "green"
                                    : statement.type === "withdraw" &&
                                      statement.status === "completed"
                                    ? "red"
                                    : "",
                                fontWeight: "bold",
                              }}
                            >
                              {(statement.type === "withdraw" &&
                                statement.status === "requested") ||
                              statement.type === "revert"
                                ? "-"
                                : statement.type === "deposit" &&
                                  statement.status === "completed"
                                ? "+"
                                : statement.status === "requested" &&
                                  statement.type === "deposit"
                                ? ""
                                : statement.type === "withdraw" &&
                                  statement.status === "rejected"
                                ? "+"
                                : ""}
                              {Math.abs(statement.amount)}
                            </span>
                          </Typography>

                          <Typography variant="body2">
                            <strong>Net Balance:</strong>{" "}
                            <span
                              style={{
                                color: statement.netBalance < 0 && "red",
                              }}
                            >
                              {" "}
                              {statement.netBalance.toFixed(2)}
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2">
                            {/* <strong>Status:</strong>{" "} */}
                            <span
                              style={{
                                color: color,
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {icon}
                              <span style={{ marginLeft: "5px" }}>
                                {statement.status}
                              </span>
                            </span>
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  );
                })}
            </Box>
          ) : (
            // Desktop view using table
            <TableContainer>
              <Table aria-label="wallet statements table" sx={{ mt: 2 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Type</StyledTableCell>
                    <StyledTableCell align="left">
                      Previous Balance
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Amount <br />
                      (Credit / Debit)
                    </StyledTableCell>
                    <StyledTableCell align="left">Net Balance</StyledTableCell>
                    <StyledTableCell align="left">
                      <TableSortLabel
                        active={true}
                        direction={sortOrder}
                        onClick={handleSortRequest}
                        sx={{ color: "#1e2466 !important" }}
                      >
                        Date & Time
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Transaction ID
                    </StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayData.length === 0 && (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={7}
                        align="center"
                        sx={{
                          color: "red",
                          fontWeight: "bold",
                          background: "#fff !important",
                          fontSize: "18px",
                        }}
                      >
                        Report Not Found .. 😔
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  {displayData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((statement) => {
                      const { icon, color } = getStatusIcon(statement.status);
                      return (
                        <StyledTableRow key={statement._id}>
                          <StyledTableCell align="left">
                            <Chip
                              size="small"
                              label={statement.type}
                              sx={getTypeStyles(statement.type)}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{
                              color: statement.previousBalance < 0 && "red",
                            }}
                          >
                            {statement.previousBalance.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{
                              color:
                                statement.type === "withdraw" ||
                                statement.type === "revert"
                                  ? "red"
                                  : statement.type === "deposit" &&
                                    statement.status === "completed"
                                  ? "green"
                                  : statement.status === "requested" &&
                                    statement.type === "deposit"
                                  ? "#1782FF"
                                  : statement.type === "withdraw" &&
                                    statement.status === "rejected"
                                  ? "green"
                                  : "",
                            }}
                          >
                            {(statement.type === "withdraw" &&
                              statement.status === "requested") ||
                            statement.type === "revert"
                              ? "-"
                              : statement.type === "deposit" &&
                                statement.status === "completed"
                              ? "+"
                              : statement.status === "requested" &&
                                statement.type === "deposit"
                              ? ""
                              : statement.type === "withdraw" &&
                                statement.status === "rejected"
                              ? "+"
                              : ""}
                            {Math.abs(statement.amount)}
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            sx={{
                              color: statement.netBalance < 0 && "red",
                            }}
                          >
                            {statement.netBalance.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {new Date(statement.createdAt).toLocaleString(
                              "en-GB"
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {statement.transactionId}{" "}
                            <IconButton
                              onClick={() =>
                                handleCopyTransactionId(statement.transactionId)
                              }
                              aria-label="copy transaction id"
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                color: color,
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {icon}
                              <span style={{ marginLeft: "5px" }}>
                                {statement.status}
                              </span>
                            </span>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sortedData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default WalletStatements;
