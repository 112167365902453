import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuFormations from "../MenuFormations";
import { useAppStore } from "../../../appStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { GameTimeCalculation } from "../../utils/GameTimeCalculation";
import axios from "axios";
import { api_url } from "../../../apiUtils";
import { blue, orange } from "@mui/material/colors";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { TailSpin } from "react-loader-spinner";
import ConfirmationDialog from "../../Games/confirmationDialog";
import Loader from "../../loader/Loader";
import useCalculateAmount from "../../utils/GamePointCalculation";

const validationSchema = yup.object({
  gamePoints: yup
    .string()
    .matches(/^[0-9]+$/, "Game points must be a number")
    .required("Game points are required"),
});

const CPNumbers = () => {
  const formik = useFormik({
    initialValues: {
      gamePoints: "",
      selectedNumbers: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleFormSubmit(values);
      console.log(values);
    },
  });

  const navigate = useNavigate();
  const {
    successDialog,
    closeDialog,
    setSuccessDialog,
    loading,
    setLoading,
    selectedGame,
    setSelectedGame,
    games,
    setGames,
    selectedNavigateMenusIds,
    walletBalance,
    creditLimit,
  } = useAppStore();

  const isMobile = useMediaQuery("(max-width:768px)");
  const [openTime, setOpentime] = useState();
  const { gameTiming } = GameTimeCalculation();
  const [selectedValue, setSelectedValue] = useState("");
  const [numberOptions, setNumberOptions] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [amounts, setAmounts] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [conFirmLoading, setConfirmLoading] = useState(false);
  const [radioValue, setRadioValue] = useState("");

  const [requestData, setRequestData] = useState({
    userInSubmission: 0,
    game: "",
    menu: "",
    bets: [],
    totalBetsAmount: 0,
  });

  const { calculateAmount } = useCalculateAmount();

  const handleCalculate = () => {
    calculateAmount(requestData);
  };

  useEffect(() => {
    if (selectedValue !== undefined) {
      getNumberOptions();
    }
  }, [selectedValue]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmationDialogOpen = async () => {
    if (walletBalance === 0) {
      toast.error("You balance is low to place the bet. please add points.");
      return;
    }
    if (walletBalance) {
      console.log("prev", walletBalance, totalAmount, creditLimit);
      let newAmount;
      newAmount = walletBalance - totalAmount;
      console.log("newAmount", newAmount);
      if (newAmount < creditLimit) {
        toast.error("You balance is low to place the bet. please add points.");
        return;
      }
    }

    if (selectedNumbers.some((number) => amounts[number] < 1)) {
      toast.warning("Minimum amount for each number should be 1");
      return;
    }
    // Show loader
    setConfirmLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setConfirmLoading(false);
    setSuccessDialog(true);
    setOpenDialog(false);
    // Reset form values
    formik.resetForm();
    setSelectedNumbers([]);
    // setSelectedGame({});
    handleCalculate();
  };

  const handleChange = async (event) => {
    const newValue = event.target.value;

    // Split the input by spaces to get individual numbers
    const numbers = newValue.split(" ").filter((num) => num.trim() !== "");

    // Check each number for repeated digits
    for (const number of numbers) {
      const digits = number.split("");
      const uniqueDigits = new Set(digits);

      if (digits.length !== uniqueDigits.size) {
        toast.error(`Repeated digits are not allowed.`);
        return; // Stop further processing if repeated digits are found
      }
    }

    // If all numbers are valid, update the state
    setSelectedValue(newValue);
    console.log("Valid input:", newValue);

    // Optionally call the function to fetch options
    // await getNumberOptions(newValue);
  };

  // const handleRadioChange = (event) => {
  //   const newRadioValue = event.target.value;
  //   console.log("value", newRadioValue);
  //   setRadioValue(newRadioValue);
  //   getNumberOptions(newRadioValue); // Pass the new value directly
  // };
  console.log("selected", selectedValue.split(" "));
  const getNumberOptions = async () => {
    try {
      const response = await axios.post(
        `${api_url}menuformation/cp`,
        { numbers: selectedValue.split(" ") }, // Split the input into an array
        {
          headers: {
            accesstoken: sessionStorage.getItem("accesstoken"),
          },
        }
      );
      const data = response.data.options;
      setNumberOptions(data);
    } catch (error) {
      console.error(error);
      // toast.error(error?.response?.data?.message);
    }
  };

  const handleCheckboxToggle = (number) => {
    if (Object.keys(selectedGame).length === 0) {
      toast.warning("Choose a game first !");
    } else {
      setSelectedNumbers((prevNumbers) =>
        prevNumbers.includes(number)
          ? prevNumbers.filter((num) => num !== number)
          : [...prevNumbers, number]
      );
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      if (selectedNumbers.length === 0) {
        toast.warning("Choose numbers !!");
        return;
      }

      setEnteredAmount(values.gamePoints);

      // Update selected numbers based on checkboxes
      const selectedNumbersList = numberOptions?.filter((number) =>
        selectedNumbers.includes(number)
      );

      // Show the selected numbers and custom amounts in the dialog
      setSelectedNumbers(selectedNumbersList);

      // Initialize amounts state with default values
      const defaultAmounts = {};
      selectedNumbersList.forEach((number) => {
        defaultAmounts[number] = values.gamePoints;
      });
      setAmounts(defaultAmounts);

      // Submission logic
      const total = values.gamePoints * selectedNumbersList.length;
      setTotalAmount(total);
      setOpenDialog(true);

      setRequestData({
        ...requestData,
        menu: selectedNavigateMenusIds,
        game: selectedGame._id,
        bets: selectedNumbersList.map((number) => ({
          number,
          amount: defaultAmounts[number],
        })),
        totalBetsAmount: total,
        userInSubmission: total,
      });
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleAmountChange = (number, value) => {
    // Parse the input value as a number
    const parsedValue = Number(value) || 0;
    if (parsedValue === 0) {
      toast.warning("minimum amount should be 1");
    }
    // Calculate the change in amount for the specific number
    const amountChange = parsedValue - (amounts[number] || 0);

    // Update the amounts state
    setAmounts((prevAmounts) => ({
      ...prevAmounts,
      [number]: parsedValue,
    }));

    // Update the total based on the amount change
    setTotalAmount((prevTotal) => prevTotal + amountChange);

    setRequestData((prevData) => ({
      ...prevData,
      bets: prevData.bets.map((bet) =>
        bet.number === number ? { ...bet, amount: parsedValue } : bet
      ),
      totalBetsAmount: prevData.totalBetsAmount + amountChange,
      userInSubmission: prevData.userInSubmission + amountChange,
    }));
  };

  const handleSelectAllCheckboxToggle = (checked) => {
    if (Object.keys(selectedGame).length === 0) {
      toast.warning("Choose a game first !");
      return;
    }
    if (checked) {
      // If "Select All" is checked, select all numbers
      setSelectedNumbers(numberOptions);
    } else {
      // If "Select All" is unchecked, deselect all numbers
      setSelectedNumbers([]);
    }
  };

  const handleDelete = (index) => {
    // Create a copy of the current selectedNumbers and amounts state
    const updatedSelectedNumbers = [...selectedNumbers];
    const updatedAmounts = { ...amounts };

    // Get the number to be deleted
    const deletedNumber = updatedSelectedNumbers[index];

    // Remove the number from selectedNumbers and amounts
    updatedSelectedNumbers.splice(index, 1);
    delete updatedAmounts[deletedNumber];

    // Update the state with the modified arrays
    setSelectedNumbers(updatedSelectedNumbers);
    setAmounts(updatedAmounts);

    // Calculate the change in total amount
    const deletedAmount = amounts[deletedNumber] || 0;
    const newTotalAmount = totalAmount - deletedAmount;
    setTotalAmount(newTotalAmount);

    setRequestData((prevData) => ({
      ...prevData,
      bets: prevData.bets.filter((bet) => bet.number !== deletedNumber),
      totalBetsAmount: prevData.totalBetsAmount - deletedAmount,
      userInSubmission: prevData.userInSubmission - deletedAmount,
    }));
  };

  useEffect(() => {
    // getNumberOptions();
    const openingTime = gameTiming();
    setOpentime(openingTime);
  }, [selectedGame]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={12} md={12}>
          {/* game */}
          <Box
            mb={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "space-between", md: "normal" },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon
                sx={{
                  color: { xs: "#ffffff", md: "#1e2466" },
                  border: "1px solid #1e2466",
                  backgroundColor: { xs: "#1e2466", md: "transparent" },
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#1e2466",
                    color: "#ffffff",
                  },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/home/menus");
                  // setSelectedGame({});
                }}
              />
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "20px", md: "24px" },
                  ml: 1,
                  color: "#1e2466",
                }}
              >
                CP
              </Typography>
            </Box>

            <Box ml={6}>
              <Typography sx={{ fontWeight: "bold" }}>
                Date :{" "}
                <span style={{ color: "red" }}>
                  {moment().format("DD-MM-YYYY")}
                </span>
              </Typography>
            </Box>
          </Box>

          <MenuFormations />
          {/* open time */}
          {games.length > 0 && (
            <Box
              mt={4}
              sx={{
                display: "flex",
                width: { xs: "100%", sm: "100%", md: "50%" },
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                },
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: "bold", mb: 0.5 }}>
                  Close Time
                </Typography>
                <Box
                  sx={{
                    border: "2px solid #bdbdbd",
                    borderRadius: 1,
                    width: "110px",
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      letterSpacing: 0.5,
                      fontSize: "18px",
                    }}
                  >
                    {openTime || "00:00"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {/* choose number menu */}
          {/* choose number menu */}
          <Box
            sx={{
              width: "100%",
              mt: 4,
              display: { xs: "block", sm: "flex" },
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Enter numbers (separated by spaces):
              </Typography>
              <TextField
                size="small"
                value={selectedValue}
                onChange={handleChange}
                placeholder="e.g., 23 34 45"
              />
            </Box>
          </Box>

          {/* game combination */}
          <Box mt={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {selectedValue.length > 1 && (
                <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  Select Numbers
                </Typography>
              )}

              {/* Select All Checkbox */}
              {selectedValue?.length > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: { xs: 2, md: 3 },
                  }}
                >
                  <Checkbox
                    checked={selectedNumbers.length === numberOptions?.length}
                    onChange={(e) =>
                      handleSelectAllCheckboxToggle(e.target.checked)
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: orange[900],
                      },
                    }}
                  />
                  <Typography sx={{ fontWeight: "bold" }}>
                    Select All
                  </Typography>
                </Box>
              )}
            </Box>

            {/* sp logic */}
            <Box>
              <Grid container>
                {numberOptions?.map((number) => (
                  <Grid item xs={3} md={2} lg={1} key={number}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {selectedValue && (
                        <Checkbox
                          checked={selectedNumbers.includes(number)}
                          onChange={() => handleCheckboxToggle(number)}
                          sx={{
                            "&.Mui-checked": {
                              color: blue[900],
                            },
                          }}
                        />
                      )}

                      <Typography
                        sx={{
                          fontWeight: selectedNumbers.includes(number)
                            ? "bold"
                            : "normal",
                        }}
                      >
                        {number}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mt={{ xs: 1, sm: 4 }} sx={{ textAlign: "center" }}>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter Game Points"
                name="gamePoints"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "400px" },
                }}
                onChange={(e) => {
                  // Ensure only numeric input
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  formik.handleChange({
                    target: { name: "gamePoints", value },
                  });
                }}
                value={formik.values.gamePoints}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.gamePoints && Boolean(formik.errors.gamePoints)
                }
                helperText={
                  formik.touched.gamePoints && formik.errors.gamePoints
                }
                InputProps={{
                  style: {
                    fontWeight: "bold",
                    letterSpacing: 0.5,
                    fontSize: "16px",
                  },
                }}
                disabled={Object.keys(selectedGame).length === 0}
              />
            </Box>

            <Box mt={3} sx={{ textAlign: "center" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={formik.handleSubmit}
                sx={{
                  backgroundColor: "#FFB320",
                  "&:hover": {
                    backgroundColor: "#FFB320",
                  },
                  textTransform: "capitalize",
                  letterSpacing: 1,
                  // fontWeight: "bold",
                  fontSize: "16px",
                  width: { xs: "100%", sm: "100%", md: "250px" },
                }}
                disabled={
                  Object.keys(selectedGame).length === 0 ||
                  !formik.values.gamePoints
                }
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} md={6}></Grid>
      </Grid>

      {/* Dialog */}
      <Dialog open={openDialog}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <DialogTitle sx={{ fontWeight: "bold" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                          Final
                        </Typography> */}

                <Typography sx={{ color: "red", fontWeight: "bold" }}>
                  {selectedGame.gameName}
                </Typography>
                <Typography mx={1} fontWeight={"bold"}>
                  {openTime?.openTime}
                </Typography>
                <Typography mx={1}>{moment().format("DD-MM-YYYY")}</Typography>
              </Box>
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleDialogClose} color="error">
                <CloseIcon />
              </Button>
            </DialogActions>
          </Box>
          <DialogContent sx={{ mt: -1 }}>
            {/* Add content for the dialog body */}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px" }}>Numbers</Typography>
                <Typography sx={{ fontSize: "14px" }}>Amount</Typography>
                <Typography sx={{ fontSize: "14px" }}>Action</Typography>
              </Box>
              <Box
                sx={{
                  px: 0.5,
                  maxHeight: "240px",
                  overflowY: "hidden",
                  "&:hover": {
                    overflowY: "auto",
                  },
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    display: "none",
                  },
                  "&:hover::-webkit-scrollbar": {
                    display: "block",
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: `#e9e9e9`,
                    borderRadius: "4px",
                  },
                  border: "1px dashed #bdbdbd",
                }}
              >
                {selectedNumbers.map((i, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px dashed #bdbdbd",
                      borderRadius: 1,
                      p: 0.5,
                      my: 1,
                    }}
                  >
                    <Typography sx={{ color: "#000000", fontWeight: "bold" }}>
                      {i}
                    </Typography>
                    <TextField
                      size="small"
                      value={amounts[i]}
                      onChange={(e) => handleAmountChange(i, e.target.value)}
                      sx={{ width: "120px", ml: 1 }}
                    />
                    <CloseIcon
                      onClick={() => handleDelete(index)}
                      sx={{
                        color: "#9e9e9e",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: "black", fontWeight: "bold" }}>
                Total:
              </Typography>
              {selectedNumbers.length === 0 ? (
                <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                  {totalAmount.toFixed(2)}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    ml: 1,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  {/* {selectedNumbers.length} x {enteredAmount} ={" "} */}
                  <p style={{ color: "red" }}>{totalAmount.toFixed(2)}</p>
                </Typography>
              )}
            </Box>

            <Box>
              <Button
                fullWidth
                variant="contained"
                onClick={handleConfirmationDialogOpen}
                sx={{
                  textTransform: "capitalize",
                  letterSpacing: 1,
                  fontWeight: "bold",
                  backgroundColor: "#FFB320",
                  "&:hover": {
                    backgroundColor: "#FFB320",
                  },
                }}
                disabled={totalAmount === 0 || conFirmLoading}
              >
                {conFirmLoading ? (
                  <TailSpin
                    color="#ffffff"
                    width={25}
                    height={"auto"}
                    strokeWidth={4}
                  />
                ) : (
                  "Confirm Submission"
                )}
              </Button>
            </Box>
            <Box mt={1}>
              {/* <Typography
                        variant="caption"
                        color="error"
                        sx={{ fontSize: "12px", fontWeight: "bold", letterSpacing: 1 }}
                      >
                        Warning !!
                      </Typography> */}
              <Typography
                variant="caption"
                color="error"
                sx={{ fontSize: "12px", display: "block" }}
              >
                After confirm submission,<b>no changes will be done</b>.
              </Typography>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

      <Dialog open={successDialog}>
        <ConfirmationDialog />
      </Dialog>
    </React.Fragment>
  );
};

export default CPNumbers;
