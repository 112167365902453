import React, { useEffect, useRef, useState } from "react";
import { useAppStore } from "../../appStore";
import axios from "axios";
import { api_url } from "../../apiUtils";
import { Box, Chip, Grid } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";

const MenuFormations = () => {
  const {
    selectedNavigateMenusIds,
    setSelectedGame,
    selectedGame,
    games,
    setGames,
    setLoadingGames,
    loadingGames,
    setForamtedGames,
    formatedGames,
  } = useAppStore();

  let token = sessionStorage.getItem("accesstoken");
  const intervalRef = useRef(null); // Define intervalRef with useRef

  const getSelectGames = () => {
    setLoadingGames(true);
    axios
      .get(`${api_url}game/get-myGames-populate/${selectedNavigateMenusIds}`, {
        headers: {
          accesstoken: token,
        },
      })
      .then(function (response) {
        let data = response?.data?.games;
        if (response.data.status === "success") {
          setForamtedGames(data);
          let individualGames = response?.data?.individualGameOff;
          let hierarchyGames = response?.data?.hierarchyGameOff;
        }
        setTimeout(() => {
          setLoadingGames(false);
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
        setGames([]);
        setLoadingGames(false);
      });
  };

  useEffect(() => {
    // Clear previous state and cancel the previous interval when menu changes
    setForamtedGames([]);
    setGames([]);

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (selectedNavigateMenusIds) {
      getSelectGames();
    }
  }, [selectedNavigateMenusIds]);

  useEffect(() => {
    const filterGames = () => {
      const currentTime = new Date();
      const systemDay = currentTime.toLocaleDateString("en-US", {
        weekday: "long",
      }); // Get the current day

      const filtered = formatedGames.filter((game) => {
        const matchingDay = game.weekTimings?.some((timing) => {
          const isCorrectDay =
            timing.day === systemDay && timing.holiday === false;

          if (isCorrectDay) {
            const [hours, minutes] = timing.time.split(":");
            const gameTime = new Date(currentTime);
            gameTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

            // Adjust the gameTime based on preClosingTime for the role
            const preClosing = game.preClosingTime.find(
              (pc) => pc.role === "customer"
            );
            if (preClosing) {
              gameTime.setMinutes(
                gameTime.getMinutes() - parseInt(preClosing.closingTime)
              );
            }

            return gameTime > currentTime;
          }

          return false;
        });

        return matchingDay;
      });
      setGames(filtered); // Update myGames state
    };

    // Calculate the time until the next minute
    const now = new Date();
    const millisecondsUntilNextMinute =
      60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

    // Run filterGames immediately, then every minute at the start of the minute
    const initialTimeout = setTimeout(() => {
      filterGames();
      intervalRef.current = setInterval(filterGames, 60000); // Save the interval ID
    }, millisecondsUntilNextMinute);

    // Run it immediately on mount
    filterGames();

    // Clean up the timeout and interval on unmount
    return () => {
      clearTimeout(initialTimeout);
      clearInterval(intervalRef.current);
    };
  }, [formatedGames]);

  useEffect(() => {
    getSelectGames();
  }, []);

  const handleChipClick = (game) => {
    setSelectedGame(game);
  };

  return (
    <div>
      {loadingGames ? (
        <span
          style={{ color: "orange", display: "flex", alignItems: "center" }}
        >
          <p style={{ marginRight: "10px" }}>fetching games</p>
          <ThreeDots height={"100%"} width={40} color="orange" />
        </span>
      ) : (
        <Box display="flex" flexWrap="wrap" spacing={1}>
          {games?.map((i, index) => (
            <Box key={index + 1} margin={0.5}>
              {" "}
              {/* Adjust margin for spacing between Chips */}
              <Chip
                label={i?.gameName}
                sx={{
                  width: "fit-content", // Adjust the width to fit the content
                  borderRadius: 2,
                  fontWeight: "bold",
                  letterSpacing: 0.5,
                  backgroundColor:
                    selectedGame._id === i._id ? "#f1c40f" : undefined,
                  color: selectedGame._id === i._id ? "#000000" : undefined,
                  "&:hover": {
                    backgroundColor:
                      selectedGame._id === i._id ? "#f1c40f" : "#f1c40f",
                  },
                }}
                onClick={() => handleChipClick(i)}
              />
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

export default MenuFormations;
